import React from 'react';
import { Routes, Route } from 'react-router-dom'; 
import Home from './Pages/Home';
import AboutPage from './Pages/Aboutus';
import ContactPage from './Pages/Contact';
import ServicePage from './Pages/Services';
import TermsPage from './Pages/Terms';
import PrivacyPolicyPage from './Pages/Politics';

const App = () => {
  return (
    <Routes> 
      <Route path="/" element={<Home />} /> 
      <Route path="/aboutus" element={<AboutPage />} /> 
      <Route path="/areas" element={<ServicePage />} /> 
      <Route path="/contact" element={<ContactPage />} /> 
      <Route path="/terms" element={<TermsPage />} /> 
      <Route path="/privacy" element={<PrivacyPolicyPage />} /> 
    </Routes>
  );
};

export default App;
