import { motion } from 'framer-motion';

const InfoSection = () => {
  return (
    <section className="info-section mt-10 mb-10 flex flex-col lg:flex-row items-center lg:items-start">
      <motion.div
        className="text-container lg:w-1/2 text-center lg:text-left"
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 1, ease: "easeInOut" }}
      >
        <h2 className="section-title text-xl lg:text-2xl font-semibold mb-4">Romero Porres y Asociados</h2>
        <p className="section-text text-base lg:text-lg mb-4">
          Romero Porres y Asociados es un estudio jurídico especializado en derecho inmobiliario, abarca la universalidad de situaciones y problemáticas que acontecen en lo relacionado a los inmuebles en sus diversas manifestaciones.
        </p>
        <p className="section-text text-base lg:text-lg">
          Nuestro estudio sostiene como principio basal que el inmueble y lo relacionado a ello manifiesta el modo de organizar el mundo humano, por ende, nuestro aporte es generar a través de nuestros servicios nuevas posibilidades de crear riqueza en sus diversas expresiones de modo seguro y eficaz.
        </p>
      </motion.div>
      <motion.div
        className="image-container lg:w-1/2 mt-6 lg:mt-0 flex justify-center hidden lg:flex"
        initial={{ opacity: 0, scale: 0.95 }}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 1, ease: "easeInOut" }}
      >
       <img
  src="/about.jpg"
  alt="Imagen representativa"
  className="object-cover w-full h-auto max-w-lg lg:max-w-xl" // Ajuste del tamaño
/>

      </motion.div>
    </section>
  );
};

export default InfoSection;
