import React from 'react';
import { motion } from 'framer-motion';
import { FaBuilding, FaHandshake } from 'react-icons/fa';

const practices = [
  {
    title: "Adquisiciones inmobiliarias",
    description: "Proporcionamos asesoría experta en la compra y venta de inmuebles, asegurando un proceso seguro y eficiente. Facilitamos la gestión de contratos, negociaciones y verificaciones legales para una transacción sin inconvenientes.",
    icon: <FaBuilding className="card-icon" />
  },
  {
    title: "Desarrollo Inmobiliario",
    description: "Brindamos consultoría especializada en la planificación y desarrollo de proyectos inmobiliarios. Desde el diseño inicial hasta la estructuración de contratos fiduciarios, garantizamos una ejecución exitosa y conforme a los estándares del sector.",
    icon: <FaHandshake className="card-icon text-3xl text-white" />
  },
];

const cardVariants = {
  offscreen: {
    opacity: 0,
    y: 50,
  },
  onscreen: {
    opacity: 1,
    y: 0,
    transition: {
      type: "tween",
      ease: "easeOut",
      duration: 1.0,
    },
  },
};

const PracticesSection: React.FC = () => {
  return (
    <section className="practices-section mt-20">
      <div className="container mx-auto px-8 lg:px-16">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.0, ease: "easeOut" }}
          className="section-title text-white tracking-widest text-center text-4xl font-light mb-16"
        >
          Nuestras Especializaciones
        </motion.h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {practices.map((practice, index) => (
            <motion.div
              key={index}
              className="card relative bg-gray-900 rounded-lg p-10 shadow-lg transition-transform transform-gpu hover:scale-102 hover:shadow-2xl hover:bg-gray-800"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
              variants={cardVariants}
            >
              <div className="flex items-start mb-6 gap-4">
                {practice.icon}
                <h3 className="card-title text-white text-2xl font-semibold">{practice.title}</h3>
              </div>
              <p className="card-description text-gray-400">{practice.description}</p>
              <div className="card-overlay" />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PracticesSection;
