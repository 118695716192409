"use client";

import { useState } from "react";
import { Link } from "react-router-dom";

const Navbar: React.FC = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavOpen(prevState => !prevState);
    document.body.classList.toggle("overflow-y-auto", !isNavOpen);
  };

  return (
    <>
      <div
        aria-hidden="true"
        className={`fixed inset-0 bg-black/40 z-30 ${isNavOpen ? "block" : "hidden"} lg:hidden`}
        onClick={toggleNavbar}
      />
      <header className="sticky top-0 w-full flex items-center h-20 z-40 bg-black text-white backdrop-filter backdrop-blur-lg">
        <nav className="relative mx-auto lg:max-w-7xl w-full px-5 sm:px-10 md:px-12 lg:px-5 flex gap-x-5 justify-between items-center">
          <div className="flex items-center min-w-max">
            <Link to="/" className="relative flex items-center gap-2.5">
              <img src="/logo.png" alt="Logo" className="w-18 h-32" />
            </Link>
          </div>

          <div
            className={`absolute top-full left-0 w-full bg-black py-8 px-5 sm:px-10 md:px-12 lg:px-0 lg:py-0 lg:w-max lg:border-none lg:relative lg:top-0 lg:translate-y-0 lg:opacity-100 lg:visible transition-transform duration-300 ease-linear ${
              isNavOpen ? "translate-y-0 opacity-100 visible" : "translate-y-10 opacity-0 invisible"
            }`}
          >
            <ul className="flex flex-col lg:flex-row gap-4 lg:items-center text-gray-300 lg:w-full lg:justify-start">
              <li>
                <Link to="/" className="relative py-2.5 px-3 text-gray-300 hover:text-gray-100 group">
                  Home
                  <span className="absolute left-0 bottom-0 w-0 h-px bg-gray-100 transition-all duration-300 group-hover:w-full group-hover:left-0"></span>
                </Link>
              </li>
              <li>
                <Link to="/aboutus" className="relative py-2.5 px-3 text-gray-300 hover:text-gray-100 group">
                  Sobre Nosotros
                  <span className="absolute left-0 bottom-0 w-0 h-px bg-gray-100 transition-all duration-300 group-hover:w-full group-hover:left-0"></span>
                </Link>
              </li>
              <li>
                <Link to="/areas" className="relative py-2.5 px-3 text-gray-300 hover:text-gray-100 group">
                  Areas De Practica
                  <span className="absolute left-0 bottom-0 w-0 h-px bg-gray-100 transition-all duration-300 group-hover:w-full group-hover:left-0"></span>
                </Link>
              </li>
              <li>
                <Link to="/contact" className="relative py-2.5 px-3 text-gray-300 hover:text-gray-100 group">
                  Contacto
                  <span className="absolute left-0 bottom-0 w-0 h-px bg-gray-100 transition-all duration-300 group-hover:w-full group-hover:left-0"></span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex items-center lg:hidden">
            <button
              aria-label="Toggle navigation"
              aria-expanded={isNavOpen}
              aria-controls="navbar-menu"
              onClick={toggleNavbar}
              className="outline-none p-3 relative"
            >
              <span 
                aria-hidden="true" 
                className={`block h-0.5 w-6 rounded bg-gray-300 transition-transform duration-300 ${isNavOpen ? "rotate-45 translate-y-1" : ""}`}
              ></span>
              <span 
                aria-hidden="true" 
                className={`block h-0.5 w-6 rounded bg-gray-300 transition-transform duration-300 ${isNavOpen ? "-rotate-45 -translate-y-1" : ""}`}
              ></span>
            </button>
          </div>
        </nav>
      </header>
      <div
        id="navbar-menu"
        className={`fixed inset-0 bg-black text-white z-30 lg:hidden transition-transform transform ${
          isNavOpen ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <ul className="flex flex-col items-center pt-20 pb-10">
          <li>
            <Link to="/" className="py-4 px-6 block text-gray-300 hover:text-gray-100">
              Home
            </Link>
          </li>
          <li>
            <Link to="/aboutus" className="py-4 px-6 block text-gray-300 hover:text-gray-100">
              Sobre Nosotros
            </Link>
          </li>
          <li>
            <Link to="/areas" className="py-4 px-6 block text-gray-300 hover:text-gray-100">
              Areas De Practica
            </Link>
          </li>
          <li>
            <Link to="/contact" className="py-4 px-6 block text-gray-300 hover:text-gray-100">
              Contacto
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
