import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const WhyChooseUs = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/areas');
  };

  return (
    <motion.section 
      className="flex flex-col md:flex-row justify-between items-center p-12 bg-gray-100 rounded-lg max-w-7xl mx-auto min-h-[400px] mt-12" 
      initial={{ opacity: 0, y: 50 }} 
      whileInView={{ opacity: 1, y: 0 }} 
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
    >
      {/* Sección de texto */}
      <div className="md:w-1/2">
        <h2 className="text-5xl font-semibold tracking-tight text-gray-900 mb-6 leading-tight">Áreas de Práctica</h2>
        <p className="text-xl text-gray-700 mb-4 leading-relaxed">
          En Romero Porres Asociados, ofrecemos asistencia integral en adquisiciones y ventas, desarrollo de negocios inmobiliarios y más. Nos especializamos en acompañamiento durante procesos de disposición de inmuebles, conformación de estados jurídicos y constructivos, además de valuaciones y estudios registrales.
        </p>
        <p className="text-lg text-gray-600 mb-6 leading-relaxed">
          También brindamos asesoramiento completo en el proceso de edificación, elaboración de estructuras contractuales fiduciarias, y representación ante organismos de control.
        </p>

        {/* Botón minimalista */}
        <motion.button 
          className="relative inline-block px-6 py-2 text-gray-900 bg-transparent border border-gray-900 rounded-full font-medium transition duration-300 ease-in-out hover:bg-gray-900 hover:text-white"
          onClick={handleButtonClick}
          whileHover={{ scale: 1.02 }} // Escala sutil
          whileTap={{ scale: 0.98 }} // Escala sutil al hacer clic
        >
          Ver áreas de práctica
        </motion.button>
      </div>

      {/* Imagen */}
      <div className="md:w-1/2 mt-6 md:mt-0">
      <motion.img
        src="/second.jpg" // Reemplaza con la URL de tu imagen
        alt="Áreas de práctica"
        className="w-full h-auto max-h-[750px] rounded-lg shadow-md" // Imagen con sombra ligera para darle más profundidad
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.7 }} // Reducido el delay para que aparezca antes
        viewport={{ once: true }}
      />

      </div>
    </motion.section>
  );
};

export default WhyChooseUs;
