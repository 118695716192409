"use client";


import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import HeroContact from '../Components/contact/HeroContact';
import ContactForm from '../Components/contact/FormContact';


const ContactPage = () => {
  return (
  <main className="relative bg-black flex flex-col overflow-hidden w-full min-h-screen">
      <Navbar />
       <HeroContact/>
      <ContactForm/>
      <Footer />
    </main>
  );
};

export default ContactPage;
