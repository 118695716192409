"use client";
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import PracticeAreasHero from '../Components/service/PracticeAreasHero';
import PracticesSection from '../Components/service/PracticesSection';
import MoreInfoService from '../Components/service/MoreService';
import MoreInfoService2 from '../Components/service/MoreService2';

const ServicePage = () => {
  return (
  <main className="relative bg-black flex flex-col overflow-hidden w-full min-h-screen">
      <Navbar />
      <PracticeAreasHero/>
      <PracticesSection/>
      <MoreInfoService/>
      <MoreInfoService2/>
      <Footer />
    </main>
  );
};

export default ServicePage;
