"use client";

import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import AboutHero from '../Components/aboutus/HeroSection';
import InfoSection from '../Components/aboutus/Info';
import VisionMissionValuesSection from '../Components/aboutus/VisionMissionValuesSection';

const AboutPage = () => {
  return (
    <main className="relative bg-black flex flex-col overflow-hidden w-full">
      <Navbar />
      <AboutHero />
      <InfoSection/>
      <VisionMissionValuesSection/>
      <Footer />
    </main>
  );
};

export default AboutPage;
