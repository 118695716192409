"use client";

import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";

const PrivacyPolicyPage = () => {
  const navigate = useNavigate(); // Cambiado de `router` a `navigate` para mayor claridad

  return (
    <div className="min-h-screen flex flex-col bg-gray-900 text-gray-200">
      <header className="py-8 bg-gray-800 text-white shadow-md">
        <div className="container mx-auto flex justify-between items-center px-6">
          <h1 className="text-3xl font-bold">Política de Privacidad</h1>
          <button
            onClick={() => navigate(-1)} // Usar `navigate(-1)` para retroceder en el historial
            className="px-4 py-2 bg-gray-800 text-white rounded-lg border border-gray-600 hover:bg-gray-700 transition duration-300 ease-in-out shadow-md focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Volver
          </button>
        </div>
      </header>

      <main className="flex-grow container mx-auto px-6 py-12">
        <h2 className="text-2xl font-semibold mb-6">Introducción</h2>
        <p className="mb-6">
          Esta Política de Privacidad describe cómo recopilamos, usamos y protegemos
          la información personal que usted nos proporciona a través de nuestro sitio web 
          y formularios de contacto. Al utilizar nuestro sitio, usted acepta las prácticas 
          descritas en esta política.
        </p>

        <h2 className="text-2xl font-semibold mb-6">
          Información que Recopilamos
        </h2>
        <p className="mb-6">
          Recopilamos la información que usted nos proporciona directamente al completar
          nuestros formularios de contacto. Esto puede incluir su nombre, dirección de correo
          electrónico, número de teléfono y cualquier otro dato que decida proporcionarnos.
        </p>

        <h2 className="text-2xl font-semibold mb-6">Uso de la Información</h2>
        <p className="mb-6">
          Utilizamos la información que nos proporciona para responder a sus consultas, ofrecerle
          información sobre nuestros servicios y mejorar la comunicación con usted. No compartimos
          su información personal con terceros sin su consentimiento, salvo cuando sea requerido 
          por la ley.
        </p>

        <h2 className="text-2xl font-semibold mb-6">
          Protección de su Información
        </h2>
        <p className="mb-6">
          Tomamos las medidas necesarias para proteger su información personal contra accesos
          no autorizados, alteraciones, divulgaciones o destrucciones. Sin embargo, debe tener en cuenta
          que ningún método de transmisión por Internet o almacenamiento electrónico es completamente seguro.
        </p>

        <h2 className="text-2xl font-semibold mb-6">Enlaces a Terceros</h2>
        <p className="mb-6">
          Nuestro sitio web puede contener enlaces a sitios de terceros. No somos responsables de las
          prácticas de privacidad ni del contenido de esos sitios web. Le recomendamos leer las políticas 
          de privacidad de cualquier sitio que visite.
        </p>

        <h2 className="text-2xl font-semibold mb-6">
          Cambios en esta Política
        </h2>
        <p className="mb-6">
          Podemos actualizar esta Política de Privacidad ocasionalmente para reflejar cambios en nuestras 
          prácticas o en la legislación aplicable. Publicaremos cualquier cambio en esta página, por lo que 
          le recomendamos revisarla periódicamente.
        </p>

        <h2 className="text-2xl font-semibold mb-6">Contacto</h2>
        <p className="mb-6">
          Si tiene alguna pregunta o inquietud sobre esta Política de Privacidad, puede contactarnos 
          a través del siguiente correo electrónico:{" "}
          <a
            href="mailto:info@romeroporres.com.ar"
            className="text-blue-400 hover:underline"
          >
            info@romeroporres.com.ar
          </a>
          .
        </p>
      </main>

      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
