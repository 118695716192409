"use client";

import { HeroSection } from '../Components/home/HeroSection';
import WhyChooseUs from '../Components/home/BentoGrid';
import Contacto from '../Components/home/Contact';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import LoadingScreen from '../Components/home/LoadingScreen';
import { useState } from 'react';

const Home = () => {
  const [loading, setLoading] = useState(true);

  const handleLoaded = () => {
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <LoadingScreen onLoaded={handleLoaded} />
      ) : (
        <>
          <main className="relative bg-black flex flex-col overflow-hidden w-full">
            <Navbar />
            {/* Eliminamos las clases de ancho limitado */}
            <div className="w-full">
              <HeroSection />
              <WhyChooseUs/>
              <Contacto />
            </div>
            <Footer />
          </main>
        </>
      )}
    </>
  );
};

export default Home;
