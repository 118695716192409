import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const MoreInfoService2: React.FC = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <section className="flex flex-col lg:flex-row items-center justify-between py-16 px-6 lg:px-24 bg-black text-white">
      {/* Imagen: a la izquierda en pantallas grandes */}
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -20 }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
        className="lg:w-2/5 mb-10 lg:mb-0"
      >
        <div className="overflow-hidden">
          <img 
            src="/build.jpg" 
            alt="Building" 
            className="object-cover w-full h-full grayscale"
            style={{ transform: 'scale(0.8)' }} 
          />
        </div>
      </motion.div>

      {/* Texto: a la derecha en pantallas grandes */}
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
        className="lg:w-2/5"
      >
        <h1 className="text-3xl lg:text-4xl font-semibold mb-4 leading-tight">
          Desarrollo Inmobiliario Estratégico
        </h1>
        <p className="text-md lg:text-lg text-gray-400 leading-relaxed">
          En nuestra consultoría, nos especializamos en convertir visiones en realidades tangibles en el ámbito inmobiliario. Desde el análisis detallado del mercado hasta la selección de terrenos adecuados, nuestro enfoque integral asegura que cada etapa del proyecto esté optimizada para el éxito. <br /><br /> Colaboramos estrechamente con nuestros clientes para diseñar estrategias personalizadas, que abarcan la gestión de permisos y licencias, así como el desarrollo de proyectos innovadores que se alineen con las tendencias actuales. Nuestra misión es maximizar el valor y la rentabilidad de cada proyecto, garantizando no solo resultados sostenibles, sino también un impacto positivo en las comunidades donde operamos.
        </p>
      </motion.div>
    </section>
  );
};

export default MoreInfoService2;
